import './App.css';
import React, {useEffect, useState, useMemo} from "react";
import axios from 'axios'
import ListDomains from "./components/ListDomains";
import Login from "./components/Login";

function App() {
    const [user, setUser] = useState({ auth:false, name:''})
    return (
    <div className="App">
            { user.auth?
                <ListDomains />
                :
                <Login setUser={setUser}/>
            }
    </div>
  );
}

export default App;
