import {Input} from "@mui/material";
import React, {useEffect} from "react";
import axios from "axios";

const ListDomains = ()=> {
    const [data, setData] = React.useState([]);
    const [searchTerm, setSearchTerm] = React.useState("");
    const filteredRows = data.filter((item) =>
        item.domain.toLowerCase().includes(searchTerm.toLowerCase())
    );

    useEffect(() => {

        axios.get("https://domains.dev.sviluppoweb.be/domains")
            .then(res => {
                setData(res.data)
            })

    }, []);

    return(
        <div className="container">
            <div className="search-container">
                <Input
                    size="lg"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </div>
            <table>
                <thead>
                <tr>
                    <th>Dominio</th>
                    <th>Attivo</th>
                    <th>Ip</th>
                    <th>Server</th>
                    <th>Php</th>
                </tr>
                </thead>
                <tbody>
                {!filteredRows ?
                    <tr>
                        <td colSpan="5">Loading...</td>
                    </tr>
                    : filteredRows.map((d) => {
                        const url = "https://www." + d.domain;
                        return (
                            <tr key={d.domain_id + "_" + d.domain}>
                                <td><a target="_blank" href={url}>{d.domain}</a></td>
                                <td>{d.active}</td>
                                <td>{d.ip_address}</td>
                                <td>{d.server_name}</td>
                                <td>{d.name}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
        )
}
export default ListDomains

